<template>
  <v-container fill-height justify-center>
    <v-card class="pa-5 my-4"
      ><v-card-title class="blue--text text--lighten-1"
        >December 15, 2021</v-card-title
      >

      <v-card-text>
        <p>
          Nulla metus metus, ullamcorper vel, tincidunt sed, euismod in, nibh.
          Quisque volutpat condimentum velit. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Nam nec
          ante. Sed lacinia, urna non tincidunt mattis, tortor neque adipiscing
          diam, a cursus ipsum ante quis turpis. Nulla facilisi. Ut fringilla.
          Suspendisse potenti. Nunc feugiat mi a tellus consequat imperdiet.
          Vestibulum sapien. Proin quam. Etiam ultrices.
        </p>
      </v-card-text>
    </v-card>
    <v-card class="pa-5"
      ><v-card-title class="blue--text text--lighten-1"
        >November 11, 2021</v-card-title
      >
      <v-card-text>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
          odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
          quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
          mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
          Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos.
        </p>

        <p>
          Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
          Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem
          at dolor. Maecenas mattis. Sed convallis tristique sem.
          <b>Vestibulum lacinia arcu eget nulla</b>. Proin ut ligula vel nunc
          egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis,
          luctus non, massa.
          <i>Lorem ipsum dolor sit amet, consectetur adipiscing elit</i>. Fusce
          ac turpis quis ligula lacinia aliquet.
          <i>Lorem ipsum dolor sit amet, consectetur adipiscing elit</i>. Mauris
          ipsum.
        </p>
      </v-card-text></v-card
    >
  </v-container>
</template>

<script>
export default {
  name: "Home",

  components: {},
};
</script>
